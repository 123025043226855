import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { Box } from "rebass"

const Hero = ({ children, backgroundImage, width }) => (
  <Box
    px={[0, 4]}
    py={4}
    style={{ position: 'relative', backgroundColor: backgroundImage ? 'transparent': '#efe7e3' }}
  >
    {backgroundImage && <Img fluid={backgroundImage} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }} />}
    <Box
      p={[3, 4]}
      bg='white'
      width={width}>
      {children}
    </Box>
  </Box>
)

Hero.propTypes = {
  children: PropTypes.array,
  backgroundImage: PropTypes.object,
  width: PropTypes.arrayOf(PropTypes.number)
}

Hero.defaultProps = {
  children: <></>,
  backgroundImage: '',
  width: [1, 3/4]
}

export default Hero
