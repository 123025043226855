import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Flex, Box, Text } from "rebass"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"
import Hero from "../components/hero"

const CloudWord = ({ children, size = 30 }) => (
  <Box>
    <Text fontSize={[size/2, size]} style={{
      color: 'white',
      lineHeight: 1.4,
      marginRight: size * 2
    }}><nobr>{children}</nobr></Text>
  </Box>
)

const FaqList = ({ items }) => (
  <ul style={{ listStyle: 'none', margin: 0, borderTop: '1px solid #712912', color: '#712911' }}>
    {items.map((item, index) => <li key={`faq-${index}`} style={{ fontWeight: 'bold', fontSize: 18, borderBottom: '1px solid #712912', padding: '6px 0', margin: 0 }}>{item}</li>)}
  </ul>
)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "fotolia-34739627-xl-3.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      anna: file(relativePath: { eq: "anna.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 350) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const arrowStyle = {
    display: 'inline-block',
    borderStyle: 'solid',
    borderColor: '#a02118',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(-45deg)',
    padding: 4,
    marginLeft: 9
  };

  return (
    <Layout>
    <SEO title="Home" />
    <Hero width={[1, 1/2]} backgroundImage={data.header.childImageSharp.fluid}>
      <Text color="#712911" fontSize={[4, 5]} my={3}>Die „chinesische“ Akupunktur</Text>
      <p>ist seit über 3000 Jahren ein bewährtes Heilverfahren in der Traditionellen Chinesischen Medizin (TCM). Ihre Wirksamkeit wird heute in zahlreichen Studien belegt. Die mit ihr erzielten Erfolge bei der Behandlung unterschiedlicher Krankheiten hat sie in den letzten Jahren immer gefragter gemacht. Besonders erwähnenswert ist die sehr erfolgreiche Behandlung von Funktionsstörungen, Schmerzzuständen und psychosomatischen Störungen. Die TCM steigert das allgemeine Wohlbefinden und eignet sich gut zur Prävention und Stressbewältigung.<br />
  Zur Wiederherstellung und zur Erhaltung der Gesundheit biete ich Ihnen unter anderem Traditionelle Chinesische Akupunktur, Elektroakupunktur, Moxibustion, Ernährungsberatung und Schröpfmassage und weitere Leistungen.</p>
      <Link to="/tcm">
        <Title small>
          mehr über TCM
          <div style={arrowStyle} />
        </Title>
      </Link>
    </Hero>
    <Box style={{ background: '#712912', textAlign: 'right' }} p={4}>
      <Flex flexWrap='wrap' justifyContent='space-between'>
        <CloudWord>Schmerzzustände</CloudWord>
        <CloudWord size={40}>Burnout</CloudWord>
        <CloudWord>Schwangerschaftsbegleitung</CloudWord>
        <CloudWord size={20}>Immunschwäche</CloudWord>
        <CloudWord size={40}>Kinderwunsch</CloudWord>
        <CloudWord>Infektneigung</CloudWord>
        <CloudWord size={25}>Wechseljahrsbeschwerden</CloudWord>
      </Flex>

      <Link to="/anwendungsgebiete" style={{ display: 'inline-block' }}>
        <Title small background='white' button={true}>
          mehr Anwendungsgebiete
          <div style={arrowStyle} />
        </Title>
      </Link>
    </Box>

    <Flex flexWrap='wrap' mx={4} my={5} justifyContent="center" alignItems="center">
      <Box width={[1, 1/2]}>
        <Link to='/ueber-mich'>
          <Flex flexWrap='wrap' style={{ background: '#efe7e3', minHeight: 300 }} justifyContent="center" alignItems="center">
            <Box width={[1, 1/2]} style={{ lineHeight: 0, textAlign: 'center' }}>
              <Img fixed={data.anna.childImageSharp.fixed} style={{ width: '100%' }} />
            </Box>
            <Box width={[1, 1/2]} p={4}>
              <Text color="#712911" fontSize={[4, 5]}>
                Erfahren Sie mehr über mich
              </Text>
              <div style={{
                ...arrowStyle,
                borderColor: '#712911',
                borderWidth: '0 3px 3px 0',
                padding: 12,
                marginTop: 18,
                float: 'right'
              }} />
            </Box>
          </Flex>
        </Link>
      </Box>
      <Box width={[1, 1/2]}>
        <Box p={4}>
          <Text color="#712911" fontSize={5} style={{ lineHeight: 1.6 }} marginBottom={3}>FAQ</Text>
          <FaqList items={['Für wen eignet sich Akupunktur?', 'Können Nebenwirkungen auftreten?', 'Übernimmt die Krankenkasse die Kosten?']} />

          <Link to='/faq'>
            <Title small>
              mehr Fragen
              <div style={arrowStyle} />
            </Title>
          </Link>
        </Box>
      </Box>
    </Flex>
  </Layout>
  )
}

export default IndexPage
